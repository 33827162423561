import { Input, Select } from 'antd';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import AC from '../AccessControl';
import { typeOptions } from '../reports/Search';
import { useReportsAccess } from '../../lib/hooks';
import styles from './ReportsSearch.module.scss';
const pathname = '/reports/search';
const ReportsSearch = ({
  placeholder = 'Search reports.',
  onSearch = () => {},
  ...props
}) => {
  const router = useRouter();
  const searchDefault = router.pathname === pathname ? router.query.q : "";
  const typeRefDefault = router.pathname === pathname ? router.query.t : typeOptions[0];
  const typeRef = useRef(typeRefDefault);
  const {
    accessibleTypes
  } = useReportsAccess();
  const options = typeOptions.filter(t => accessibleTypes.includes(t)).map(t => ({
    label: t,
    value: t
  }));
  return options.length > 0 && <AC.CheckAccess rule={AC.ruleFromHref({
    pathname
  })}>
			<Input.Group compact className={styles["reports-search-bar"]}>
				<Select options={options} defaultValue={typeRefDefault} key={typeRefDefault} onChange={t => typeRef.current = t} className={styles["type-selector"]} {...props} />
				<Input.Search placeholder={placeholder} defaultValue={searchDefault} key={searchDefault} onSearch={search => {
        const query = {
          q: search,
          t: typeRef.current
        };
        router.push({
          pathname,
          query
        });
        onSearch(query);
      }} style={{
        maxWidth: 500
      }} {...props} />
			</Input.Group>
		</AC.CheckAccess>;
};
export default ReportsSearch;